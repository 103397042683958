<template>
  <div class="container">
    <Breadcrumb :breadcrumbList="[ { 'name': 'ADMIN DASHBOARD ', 'link': '/admin' },{ 'name': 'USERS' } ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Users</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4">
          <Cards type="svg" image="fa-users" title="All Users" :route="'/admin/users/list/all'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" icon-type="fa-regular" image="fa-clock" title="Users Currently Away" :route="'/admin/users/list/away'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="roleCheckBan">
          <Cards type="svg" image="fa-eye-slash" title="Shadow Banned Users" :route="'/admin/users/list/shadowBan'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="roleCheckWatch">
          <Cards type="svg" image="fa-solid fa-binoculars" title="Watched Users" :route="'/admin/users/list/watched'"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cards from '../../../components/Admin/AdminCards'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
export default {
  name: 'AdminUsersLandingPage',
  components: {
    Cards,
    Breadcrumb
  },
  data () {
    return {
      roleCheckBan: this.$store.getters.hasRole('ROLE_ADMIN_SHADOW_BAN'),
      roleCheckWatch: this.$store.getters.hasRole('ROLE_ADMIN_WATCHED')
    }
  }
}
</script>

<style scoped>

</style>
